import Routes from 'Routes'
import Button from 'rv-components-onze/src/Button'

import Header from 'components/Header'
import Menu from 'components/Menu'
import SectionSafetyFirst from 'components/SectionSafetyFirst'
import Hero from './components/Hero'
import EncourageEmployee from 'components/EncourageEmployee'
import FooterNew from 'components/FooterNew'
import SectionFinancialHealth from 'components/SectionFinancialHealth'
import OurWallet from 'components/OurWalletSection'
import AnyQuestionSection from 'components/AnyQuestionSection'
import Media from './components/Media'

import SectionForm from 'components/SectionForm'
import Sidebar from 'components/Sidebar'

import useReCaptcha from 'hooks/useReCaptcha'
import enhancer from './hooks'

const { yourCompany } = Routes

const Home = ({ onClickSidebar, bulletList }) => {
  useReCaptcha()

  return (
    <div className="home-page">
      <div className="home-page__scroll-limit">
        <Header hasTransition />
        <Menu />
        <Sidebar>
          <SectionForm customClass="sidebar" />
        </Sidebar>
        <Hero />
        <EncourageEmployee
          imageSrc="/rebranding-encourage-img.png"
          title="A Onze é a primeira fintech focada em previdência e saúde
              financeira para os colaboradores da sua empresa."
          buttonLabel="Saiba mais"
          buttonLink={yourCompany.path}
          bulletList={bulletList}
          customClass="container"
        />
        <SectionFinancialHealth />
        <Media />
        <OurWallet />
        <SectionSafetyFirst />
        <div className="home-page__button-wrapper">
          <Button
            label="Agende uma conversa"
            iconId="right-arrow"
            iconPosition="right"
            customClass="home-page__button"
            onClick={onClickSidebar}
          />
        </div>
      </div>
      <AnyQuestionSection />
      <FooterNew />
    </div>
  )
}

export default enhancer(Home)
