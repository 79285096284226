import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, A11y } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import { isMobile } from 'utils/getDevice'

import { sliderImages } from './constants'

import Typography from 'rv-components-onze/src/Typography'

SwiperCore.use([Pagination, A11y])

const Media = () => {
  return (
    <section className="media-section">
      <div className="container">
        <Typography as="p" variant="body1" className="media-section__subtitle" weight="bold">
          #OnzeNaMídia
        </Typography>
        <Typography as="h4" variant="headline4" className="media-section__title">
          O que estão falando de nós
        </Typography>

        <div className="media-section__slider">
          <Swiper
            slidesPerView={isMobile() ? 1 : 5}
            pagination={{ clickable: true }}>
            {sliderImages.map((item, idx) => (
              <SwiperSlide>
                <a href={item.url} target="_blank">
                  <img
                    className="management-hero__slider--image"
                    src={`/images/${item.src}`}
                    alt={item.alt}
                    key={`${item.alt}-${idx}`}
                  />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default Media
