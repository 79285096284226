
export const sliderImages = [
  {
    src: 'logo-estadao.jpg',
    alt: 'Logo Estadão',
    url: 'https://link.estadao.com.br/noticias/inovacao,prevtech-onze-recebe-aporte-de-r-53-milhoes-para-correr-atras-do-tempo-perdido-na-pandemia,70003725382'
  },
  {
    src: 'logo-exame.jpg',
    alt: 'Logo Exame',
    url: 'https://exame.com/exame-in/fintech-que-quer-reinventar-a-previdencia-privada-capta-r-53-milhoes/'
  },
  {
    src: 'logo-folha.jpg',
    alt: 'Logo Folha de São Paulo',
    url: 'https://www1.folha.uol.com.br/mercado/2021/04/fintechs-de-previdencia-privada-devem-crescer-com-pandemia.shtml'
  },
  {
    src: 'logo-neo-feed.jpg',
    alt: 'Logo Neo Feed',
    url: 'https://neofeed.com.br/blog/home/o-que-o-visionario-das-fintechs-enxergou-na-brasileira-onze/'
  },
  {
    src: 'logo-valor-investe.jpg',
    alt: 'Logo Valor Investe',
    url: 'https://valorinveste.globo.com/produtos/previdencia-privada/noticia/2021/03/25/previdencia-privada-so-4-dos-10-maiores-fundos-de-renda-fixa-bateram-o-cdi-em-2020-e-6-em-10-multimercados-veja-quem-sao.ghtml'
  },
  {
    src: 'logo-valor-economico.jpg',
    alt: 'Logo Valor Econômico',
    url: 'https://valor.globo.com/carreira/noticia/2021/01/15/estresse-financeiro-dos-brasileiros-na-pandemia-impacta-relacoes-profissionais.ghtml'
  },
  {
    src: 'logo-exame-invest.jpg',
    alt: 'Logo Exame Invest',
    url: 'https://invest.exame.com/oi/maiores-fundos-de-previdencia-sequer-superam-o-cdi-veja-ranking'
  },
  {
    src: 'logo-r7.jpg',
    alt: 'Logo R7',
    url: 'https://recordtv.r7.com/hoje-em-dia/videos/estresse-financeiro-atinge-sete-em-cada-dez-trabalhadores-brasileiros-22022021'
  },
  {
    src: 'logo-infomoney.jpg',
    alt: 'Logo InfoMoney',
    url: 'https://www.infomoney.com.br/minhas-financas/pgbl-errar-no-modelo-da-declaracao-de-ir-pode-custar-ate-85-mais-imposto/'
  },
]