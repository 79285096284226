const useHome = () => {
  const bulletList = [
    {
      description: 'Uma solução com tecnologia <b>100% digital</b>',
    },
    {
      description: 'Fácil de implementar',
    },
    {
      description: '<b>Altamente flexível</b> e com benefícios fiscais',
    },
    {
      description: 'Atrai e retém os <b>melhores</b> talentos',
    },
    {
      description:
        'Planos com o <b>incentivo</b> que a empresa quiser oferecer',
    },
  ]

  return { bulletList }
}

export default useHome
